
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { IEdition, IDetail } from '@/data-types';

export default Vue.extend({
    props: {
      pageType: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        editions: [],
        selectedEditionName: '',
        selectedEditionId: 0,
        keysData: [] as string[],
        showOngoingState: false,
        message: '',
        messageType: 'ok',
      };
    },
    methods: {
      assignEditions() {
        this.editions = this.editionsList.filter((x: IEdition) => {
          return this.companyEditions.indexOf(x.id) !== -1 && x.id !== this.activeEdition;
        }).map((x: IEdition) => {
          return x.name;
        });
      },
      getSelectedEditionId() {
        const selectedEdition = this.editionsList.find((x: IEdition) => {
          return x.name === this.selectedEditionName;
        });

        return selectedEdition.id;
      },
      migrateData() {
        if (this.selectedEditionName !== '') {
          const confirmed = confirm('Wykonanie tej akcji nadpisze wszystkie dane w zakładce. Czy na pewno chcesz je pobrać?');
          if (confirmed) {
            this.keysData.length = 0;
            this.showOngoingState = true;
            this.selectedEditionId = this.getSelectedEditionId();

            if (this.companyData && this.companyData.details) {
              const newDetails = this.companyData.details.filter((x: IDetail) => {
                return x.key.startsWith(this.selectedEditionId.toString());
              });

              Object.keys(newDetails).map((id) => {
                const activeEditionKey = newDetails[id].key.replace(`${this.selectedEditionId}_`, '');

                if (!activeEditionKey.includes('booth_number')) {
                  if (newDetails[id].key.includes(this.pageType) && !activeEditionKey.includes('disabled')) {
                    this.keysData.push(activeEditionKey);
                  }
                }
              });
              this.dispatchDataToServer();
            }
          }
          return;
        } else {
          this.message = 'Wybierz najpierw edycję, z której chcesz pobrać dane!';
          this.messageType = 'error';
        }
      },
      dispatchDataToServer() {
        if (this.keysData.length > 0 && this.selectedEditionId !== 0) {
          this.showOngoingState = false;
          const dispatchData = {
            source: this.selectedEditionId,
            keys: this.keysData,
          };

          this.$store.dispatch('copyCompanyDataBetweenEditions', dispatchData).then((response: any) => {
            this.showOngoingState = false;
            if (response.status === 200) {
              this.message = 'Dane zostały nadpisane.';
              this.messageType = 'ok';
              this.$emit('success')
            } else {
              this.message = 'Wystąpił błąd, nie udało się pobrać danych!';
              this.messageType = 'error';
            }
          });
        } else {
          this.message = 'W wybranej edycji nie zostały przypisane żadne dane w tej zakładce';
          this.showOngoingState = false;
        }
      },
    },
    computed: {
    ...mapGetters([
      'editionsList',
      'activeEdition',
      'companyEditions',
      'companyData',
    ]),
  },
  mounted() {
    this.assignEditions();
  },
  watch: {
    editionsList() {
      this.assignEditions();
    },
  },
});
